.category-container {
  padding: 20px;
  background: rgba(255, 255, 255, 0.2);
  min-height: 100vh;
  color: white;
}

.category-container h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #34eb86;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.add-category-button {
  background: linear-gradient(to right, #ff7e5f, #feb47b);
  color: white;
}

.add-category-button:hover {
  background: linear-gradient(to right, #f9ca24, #f0932b);
  transform: scale(1.05);
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  background: rgba(15, 6, 6, 0.647);
  border-radius: 10px;
  color: white;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.styled-table th, .styled-table td {
  padding: 15px;
  text-align: center;
}

.styled-table th {
  background-color: rgba(255, 159, 67, 0.9);
  color: white;
  font-size: 1.1rem;
  text-transform: uppercase;
}

.styled-table tr:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.actions a {
  font-size: 0.9rem;
  padding: 5px 10px;
  border-radius: 8px;
  transition: all 0.2s ease;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  margin: 5px;
}

.actions a:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.3);
}

.actions .delete {
  color: red;
  border: 1px solid red;
}

.modal-box {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.95);
  color: black;
  width: 400px;
  margin: auto;
  border-radius: 12px;
  position: relative;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
}

.modal-box button {
  background: linear-gradient(to right, #2ecc71, #27ae60);
  color: white;
  padding: 10px 20px;
  font-size: 1.1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.modal-box button:hover {
  transform: scale(1.05);
  background: linear-gradient(to right, #27ae60, #1abc9c);
}

.search-bar {
  background: rgba(117, 129, 160, 0.723);
  border-radius: 8px;
  color: #ffffff;
  width: 250px;
  padding: 10px;
  margin-right: 20px;
  margin: 0 auto;
}

.checkbox-label {
  color: #ffffff;
  font-weight: bold;
}
.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
