.sticker-container {
  padding: 20px;
  background: rgba(255, 255, 255, 0.2);
  min-height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sticker-container h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #34eb86;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: nowrap;
}

.add-sticker-button {
  background: linear-gradient(to right, #ff7e5f, #feb47b);
  color: white;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 12px;
  font-size: 1.1rem;
  transition: background 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.add-sticker-button:hover {
  background: linear-gradient(to right, #f9ca24, #f0932b);
  transform: scale(1.05);
}

.search-bar {
  flex-grow: 1;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}

.filter-container {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.checkbox-label {
  color: #ffffff;
  font-weight: bold;
}

.table-wrapper {
  overflow-x: auto;
  width: 100%;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  background: rgba(15, 6, 6, 0.647);
  border-radius: 10px;
  color: white;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.styled-table th,
.styled-table td {
  padding: 15px;
  text-align: center;
}

.actions a {
  font-size: 1rem;
  cursor: pointer;
  padding: 5px 10px;
  margin: 2px;
}

.modal-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.save-button {
  background-color: #007bff !important;
  color: white !important;
}

.save-button:hover {
  background-color: #0056b3 !important;
}

.close-button {
  background-color: #ff4d4f !important;
  color: white !important;
}

.close-button:hover {
  background-color: #d32f2f !important;
}

@media (max-width: 768px) {
  .controls,
  .filter-container {
    flex-direction: column;
    align-items: center;
  }

  .styled-table th,
  .styled-table td {
    font-size: 0.9rem;
  }
  
  .filter-container {
    gap: 5px;
    justify-content: center;
  }

  .checkbox-label {
    font-size: 0.9rem;
  }
}
