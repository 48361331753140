
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-image: url('logo3.jpg'); 
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 1;
}

.tagline {
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.subtext {
  font-size: 1.2rem;
  margin-top: 0.5rem;
  color: #e0e0e0;
}


.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  z-index: 2;
  animation: fadeIn 1.5s ease-in-out;
}


@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.login-box {
  max-width: 400px;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  z-index: 3;
}
