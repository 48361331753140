/* General Container */
.app-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.app-header {
  text-align: center;
  padding: 30px;
  background-color: #002200;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  color: #a1f0a1;
}

/* Card Styles */
.card {
  background-color: #004d00;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  padding: 25px;
  margin: 25px 0;
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.02);
}

.card-prices {
  background: linear-gradient(135deg, #006600, #00aa00);
  color: #f5f5f5;
}

.card-graph {
  background: linear-gradient(135deg, #005500, #008800);
}

/* Price Update Form */
.price-update-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #004400;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  color: #e6ffe6;
}

.price-update-form label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1.1em;
}

.price-update-form input[type="number"] {
  width: 100%;
  max-width: 100px;
  padding: 8px;
  margin-left: 15px;
  border-radius: 8px;
  border: none;
  background-color: #003300;
  color: #a1f0a1;
  font-size: 1em;
  text-align: right;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

.price-update-form input[type="number"]:focus {
  outline: none;
  background-color: #004d00;
}

.price-update-form .button {
  width: fit-content;
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 1em;
  font-weight: bold;
  background-color: #007700;
  color: #ffffff;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.price-update-form .button:hover {
  background-color: #00aa00;
}

/* Subscription Table */
.subscription-table {
  width: 100%;
  border-collapse: collapse;
}

.subscription-table th, .subscription-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #006600;
  color: #e6ffe6;
}

.subscription-table th {
  background-color: #005500;
  color: #a1f0a1;
}

.chart-container {
  background-color: #004400;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Button Styles */
.button {
  background-color: #00aa00;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #007700;
}

.price {
  font-weight: bold;
  color: #f0fff0;
  font-size: 1.2em;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .app-container {
    padding: 10px;
  }

  .card {
    padding: 20px;
    margin: 15px 0;
  }

  .price-update-form label {
    font-size: 1em;
  }

  .price-update-form input[type="number"] {
    width: 80px;
  }

  .subscription-table th, .subscription-table td {
    padding: 10px;
  }

  .app-header {
    font-size: 1.5em;
  }
}

@media (max-width: 480px) {
  .app-header {
    padding: 20px;
    font-size: 1.3em;
  }

  .card {
    padding: 15px;
  }

  .price-update-form label {
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.9em;
  }

  .price-update-form input[type="number"] {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }

  .price-update-form .button {
    width: 100%;
  }

  .subscription-table th, .subscription-table td {
    font-size: 0.9em;
    padding: 8px;
  }
}
