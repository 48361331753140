/* Feedback Screen */
.feedback-screen {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  padding: 40px;
  flex-wrap: wrap;
}

/* Feedback Box */
.feedback-box {
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white */
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
  backdrop-filter: blur(10px); /* Glassmorphism effect */
  margin: 20px;
}

/* Header */
.feedback-header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  flex-wrap: wrap;
}

.feedback-header .MuiTypography-root {
  color: #2e7d32; /* Dark green */
  margin-left: 12px;
  font-weight: bold;
  flex: 1 1 auto;
}

/* Feedback Input */
.feedback-input {
  display: flex;
  gap: 16px;
  margin-top: 24px;
  flex-wrap: wrap;
}

.feedback-input .MuiTextField-root {
  flex-grow: 1;
  background-color: #fff;
  border-radius: 8px;
  min-width: 250px;
}

.feedback-input .MuiButton-containedPrimary {
  background-color: #2e7d32; /* Dark green */
  color: #fff;
  padding: 12px 24px;
  font-size: 16px;
  text-transform: none;
  border-radius: 8px;
}

.feedback-input .MuiButton-containedPrimary:hover {
  background-color: #1b5e20;
}

/* Chart Container */
.chart-container {
  margin-top: 40px;
  width: 100%;
}

/* Feedback List */
.feedback-list {
  margin-top: 40px;
  max-height: 300px;
  overflow-y: auto;
}

/* Feedback Item */
.feedback-item {
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.3s;
}

.feedback-item:hover {
  background-color: #f1f8e9; /* Light green on hover */
}

.feedback-item:last-child {
  border-bottom: none;
}
/* Feedback Table */
.feedback-table {
  margin-top: 40px;
}

.MuiTableContainer-root {
  border-radius: 12px;
  overflow: hidden;
}

.MuiTableCell-head {
  background-color: #2e7d32; /* Dark green */
  color: #fff;
  font-weight: bold;
}

.MuiTableCell-root {
  border-bottom: none;
  padding: 16px;
}

.MuiTableRow-root:nth-of-type(even) {
  background-color: rgba(46, 125, 50, 0.05);
}

.MuiTableRow-root:hover {
  background-color: rgba(46, 125, 50, 0.1);
}

@media (max-width: 768px) {
  .MuiTableCell-root {
    padding: 12px;
  }

  .MuiTableCell-head {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .MuiTableCell-root {
    padding: 8px;
    font-size: 12px;
  }

  .MuiTableCell-head {
    font-size: 12px;
  }
}


/* Scrollbar Styling */
.feedback-list::-webkit-scrollbar {
  width: 8px;
}

.feedback-list::-webkit-scrollbar-thumb {
  background-color: #a5d6a7;
  border-radius: 4px;
}

/* Typography Adjustments */
.MuiTypography-root {
  color: #333;
}

/* Button Ripple Effect Color */
.MuiButtonBase-root .MuiTouchRipple-child {
  background-color: rgba(46, 125, 50, 0.3);
}

/* Input Field Focus Color */
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #2e7d32;
}

/* Placeholder Text Color */
.MuiInputLabel-root {
  color: #666;
}

/* Tooltip Styling */
.MuiTooltip-tooltip {
  background-color: #2e7d32;
  color: #fff;
  font-size: 14px;
}

/* Chart Styling */
.recharts-default-tooltip {
  background-color: rgba(255, 255, 255, 0.9) !important;
  border: none !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
}

/* Responsive Design */
@media (max-width: 768px) {
  .feedback-box {
    padding: 30px;
  }

  .feedback-input {
    flex-direction: column;
  }

  .feedback-input .MuiButton-containedPrimary {
    width: 100%;
    margin-top: 16px;
  }

  .chart-container {
    margin-top: 30px;
  }

  .feedback-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .feedback-header .MuiTypography-root {
    margin-left: 0;
    margin-top: 16px;
  }
}

@media (max-width: 480px) {
  .feedback-box {
    padding: 20px;
  }

  .feedback-input {
    gap: 8px;
  }

  .feedback-input .MuiTextField-root {
    min-width: 100%;
  }
}
